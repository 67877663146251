<template>
  <div class="content-container price-list-container page-wrap">
    <section class="price-list-intro">
      <h1 class="heading-1">Ceník pronájmu a služeb</h1>
      <p class="desc">Pronájem a služby lze platit v hotovosti.</p>

      <table class="price-list-table rental">
        <th class="price-list-table-header">
          <td class="price-list-table-header-column">Pronájem apartmánu</td>
        </th>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Noc</span>
            <small>(od 16:00 - 10:00 nebo dle dohody)</small>
          </td>
          <td class="price-list-table-column price"><span>3&nbsp;690,- Kč</span></td>
        </tr>
        <!--
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Víkend</span>
            <small>(od 16:00 - 10:00 nebo dle dohody)</small>
          </td>
          <td class="price-list-table-column price"><span>6&nbsp;900,- Kč</span></td>
        </tr>
        -->
      </table>

      <table class="price-list-table services">
        <th class="price-list-table-header">
          <td class="price-list-table-header-column">Služby</td>
        </th>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Romanticky vyzdobený apartmán, včetně vyzdobené postele</span>
          </td>
          <td class="price-list-table-column price"><span>100,- Kč</span></td>
        </tr>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Bílé víno</span>
          </td>
          <td class="price-list-table-column price"><span>220,- Kč</span></td>
        </tr>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Červené víno</span>
          </td>
          <td class="price-list-table-column price"><span>220,- Kč</span></td>
        </tr>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Bohemia sekt</span>
          </td>
          <td class="price-list-table-column price"><span>220,- Kč</span></td>
        </tr>
        <tr class="price-list-table-row">
          <td class="price-list-table-column title">
            <span>Bonboniéra</span>
            <small>(Srdce)</small>
          </td>
          <td class="price-list-table-column price"><span>200,- Kč</span></td>
        </tr>
      </table>
            <p class="desc">Pro individuální termíny volejte na telefonní číslo <strong>+420 733 120 972</strong> nebo na emailovou adresu <strong>info@jupiterwellness.cz</strong>.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PriceList",
  components: {
  
  },
  metaInfo: {
      title: 'Ceník',
      titleTemplate: '%s | Jupiter Wellness',
      meta: [
      {
        'property': 'og:title',
        'content': 'Ceník',
        'template': '%s | Jupiter Wellness',
        'vmid': 'og:title'
      },
      {
        'property': 'og:url',
        'content': 'https://jupiterwellness.cz/cenik',
        'vmid': 'og:url'
      },
    ]
    },
  data: function () {
    return {

    };
  },
  mounted() {

  },
  methods: {
  },
  computed: {

  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.price-list-container {
  .price-list-intro {
    text-align: center;

    .desc {
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      color: #48484a;
      width: percent(600, 768);
      margin: 0 auto rem(50) auto;
    }
  }

  .price-list-table {
    width: 100%;
    margin: 0 auto rem(80) auto;

    @include breakpoint(tablet) {
      width: percent(600, 768);
    }


    &.services {
      margin: 0 auto rem(32) auto;
    }


    .price-list-table-header {
      display: flex;
      width: 100%;

      .price-list-table-header-column {
        display: flex;
        height: rem(40);
        background-color: #e3dcd180;
        width: 100%;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet) {
          height: rem(56);
        }
      }
    }

    .price-list-table-row {
      border-bottom: 1px solid #e3dcd1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: rem(92);

      @include breakpoint(tablet) {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
      }


      &:last-child {
        border: none;
      }

      .price-list-table-column {
        height: auto;
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
          //height: rem(76);
        }

        &.title {
          flex-direction: column;
          align-content: left;
          align-items: flex-start;
          justify-items: center;
          justify-content: center;
          margin-top: rem(16);
          margin-top: rem(16);

          span {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #212123;
            text-align: left;

            @include breakpoint(tablet) {
              font-size: 20px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.4;
              letter-spacing: normal;
              color: #212123;
            }


          }

          small {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #48484a;


            @include breakpoint(tablet) {
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.75;
              letter-spacing: normal;
              color: #48484a;
              display: block;
            }


          }
        }

        &.price {
          margin-bottom: rem(14);

          @include breakpoint(tablet) {
            margin-bottom: 0;
          }

          span {
            font-size: 20px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: right;
            color: #212123;

            @include breakpoint(tablet) {
              width: rem(125);
            }
          }
        }

      }
    }
  }


}

</style>